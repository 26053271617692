import React from "react"
import { createIcon } from "@chakra-ui/icons"

const DiscordIcon = createIcon({
  displayName: "DiscordIcon",
  viewBox: "0 0 50 50",
  path: (
    <path
      d="m41.625 10.77c-3.98-3.204-10.277-3.747-10.547-3.766a.992.992 0 0 0 -.988.586 6.63 6.63 0 0 0 -.305.832c2.633.445 5.867 1.34 8.793 3.156a1 1 0 1 1 -1.055 1.7c-5.03-3.123-11.312-3.278-12.523-3.278-1.21 0-7.496.156-12.523 3.277a1 1 0 0 1 -1.055-1.7c2.926-1.811 6.16-2.71 8.793-3.151-.152-.496-.29-.809-.3-.836a.987.987 0 0 0 -.993-.586c-.27.02-6.567.562-10.602 3.809-2.105 1.948-6.32 13.339-6.32 23.187 0 .176.047.344.133.496 2.906 5.11 10.84 6.445 12.648 6.504h.031a1 1 0 0 0 .81-.41l1.827-2.516c-4.933-1.273-7.453-3.437-7.597-3.566a1 1 0 1 1 1.324-1.5c.058.055 4.699 3.992 13.824 3.992 9.14 0 13.781-3.953 13.828-3.992a1 1 0 0 1 1.41.094.996.996 0 0 1 -.09 1.406c-.144.129-2.664 2.293-7.597 3.566l1.828 2.516a1 1 0 0 0 .809.41h.03c1.81-.059 9.743-1.395 12.65-6.504.085-.152.132-.32.132-.496 0-9.848-4.215-21.238-6.375-23.23zm-23.125 19.23c-1.934 0-3.5-1.79-3.5-4s1.566-4 3.5-4 3.5 1.79 3.5 4-1.566 4-3.5 4zm13 0c-1.934 0-3.5-1.79-3.5-4s1.566-4 3.5-4 3.5 1.79 3.5 4-1.566 4-3.5 4z"
      fill="currentColor"
    />
  ),
})

export default DiscordIcon
