import { Text } from "@chakra-ui/react"
import { CookieConsent } from "react-cookie-consent"

import Link from "common/components/Link"
import { EXTERNAL_ROUTES } from "common/constants/routes"

const PrivacyPolicyBanner = () => {
  return (
    <CookieConsent
      buttonStyle={{
        color: "#ffffff",
        fontSize: "14px",
        backgroundColor: "#2d3748",
        border: "1px solid #2d3748",
        fontFamily: "DM Sans",
        fontWeight: "bold",
        borderRadius: "6px",
        padding: "8px 26px",
        margin: "10px 20px 0 10px",
      }}
      buttonText="Accept"
      declineButtonStyle={{
        color: "#2d3748",
        fontSize: "14px",
        border: "1px solid #ccc",
        backgroundColor: "white",
        fontFamily: "DM Sans",
        fontWeight: "bold",
        borderRadius: "6px",
        padding: "8px 22px",
        margin: "10px 8px 0 0",
      }}
      declineButtonText="Reject"
      enableDeclineButton={false}
      style={{
        background: "#f7f5ff",
        borderTop: "1px solid #e0d6ff",
        padding: "10px 0",
      }}
    >
      <Text color="gray.700" fontWeight="bold" mr={3} mt={0} textStyle="md">
        We value your privacy.
      </Text>
      <Text color="gray.700" mr={3} textStyle="md">
        We use cookies to improve user experience. We want to understand how our
        website is used.
      </Text>
      <Text color="gray.700" mr={3} textStyle="md">
        You can find out more about our use of cookies in our{" "}
        <Link isExternal href={EXTERNAL_ROUTES.privacyPolicy()}>
          <Text as="span" fontWeight="bold" textStyle="md">
            Privacy Policy
          </Text>
        </Link>
        .
      </Text>
    </CookieConsent>
  )
}

export default PrivacyPolicyBanner
