import type { FC } from "react"
import React from "react"
import dayjs from "dayjs"
import type { BoxProps } from "@chakra-ui/react"
import { Text } from "@chakra-ui/react"

import { DATE_FORMATS } from "common/constants/date"

const Copyright: FC<BoxProps> = ({ ...boxProps }) => (
  <Text
    color="gray.400"
    fontWeight="bold"
    textStyle="md"
    w="max-content"
    {...boxProps}
  >
    &copy; {dayjs().format(DATE_FORMATS.year)} Tally
  </Text>
)

export default Copyright
